import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  ${({ theme, slug }) => `
    display: flex;
    flex-direction: column;
    min-width: calc(33% - 2 * ${theme.spaces[2]});
    align-items: center;
    justify-content: flex-start;
    font-family: ${theme.fonts.proxima};
    padding: ${theme.spaces[2]};
    color: inherit;
    text-align: center;
    flex-grow: 1;
    height: 15rem;

    ${
      slug &&
      `
        &:hover {
            cursor: pointer;
        }
      `
    }
  `}
`

const SpeakerImage = styled.img`
  ${({ theme }) => `
    object-fit: cover;
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    margin-bottom: ${theme.spaces[0]};
    object-position: top;
  `}
`

const ProgramTitle = styled.div`
  font-style: italic;
  max-width: 16rem;
  text-align: center;
`

const Bold = styled.span`
  font-weight: bold;
`

const Speaker = ({ imgSrc, name, program, slug }) => (
  <Container slug={slug} onClick={() => navigate(`/programs/speakers/${slug}`)}>
    <SpeakerImage src={imgSrc} />
    <div>
      <Bold>{name}</Bold>
    </div>
    <ProgramTitle>{program}</ProgramTitle>
  </Container>
)

export default Speaker
