import React from "react"
import FoldContainer from "../components/FoldContainer"
import MarginContainer from "../components/MarginContainer"
import ShortHero from "../components/ShortHero"
import Speaker from "../components/Speaker"
import styled from "styled-components"
import GoBack from "../components/GoBack"
import { navigate } from "gatsby"
import SectionHeader from "../components/SectionHeader"

const SpeakerContainer = styled.div`
  color: white;
  padding-top: 5rem;
  width: 100%;
`

const Header = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.rg};
    color: ${theme.colors.darkBlue};
    font-weight: bold;
    margin: ${theme.spaces[1]} 0;
  `}
`

const Paragraph = styled.p`
  ${({ theme }) => `
    font-family: ${theme.fonts.proxima};
    white-space: pre-wrap;
    text-align: justify;
    margin-bottom: ${theme.spaces[2]};
  `}
`

const SpeakerTemplate = ({ pageContext }) => (
  <>
    <ShortHero>
      <SpeakerContainer>
        <Speaker {...pageContext} />
      </SpeakerContainer>
    </ShortHero>
    <FoldContainer>
      <MarginContainer>
        <GoBack onClick={() => navigate("/programs")}>&lt;&nbsp;Go Back</GoBack>
        {pageContext.introduction && (
          <>
            <SectionHeader>Introduction</SectionHeader>
            <Paragraph>{pageContext.introduction}</Paragraph>
          </>
        )}
      </MarginContainer>
    </FoldContainer>
    {pageContext.speech && (
      <FoldContainer>
        <MarginContainer>
          <SectionHeader>Speech</SectionHeader>
          <Header>{pageContext.program}</Header>
          <Paragraph>{pageContext.speech}</Paragraph>
        </MarginContainer>
      </FoldContainer>
    )}
  </>
)

export default SpeakerTemplate
